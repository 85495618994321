import React from 'react';
import ExpandingGrid from './index';

const $module = $('#expanding-grid');

if ($module.length){
	ReactDOM.render(
		<ExpandingGrid initialState={JSON.parse(decodeURI(document.getElementById('initial-state').innerHTML))}/>,
		$module[0]
	);
}
