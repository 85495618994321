import FaqSearch from './index.js';
import React from 'react';

const $module = $('#faq-search');

if ($module.length){

	ReactDOM.render(
		<FaqSearch initialState={JSON.parse(decodeURI(document.getElementById('initial-state').innerHTML))}/>,
		$module[0]
	);
}
