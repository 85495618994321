import React, { Component} from 'react';
import SearchInputTypeahead from './../common/SearchInputTypeahead';
import SiteSearchFilters from './SiteSearchFilters';
import SearchResult from './SearchResult';

class SiteSearchApp extends Component {

	constructor(props){
		super(props);

		this.state = props.initialState;
		this.state.page = this.state.page || 1;
		this.state.filterOptions = this.state.filterOptions || [];
		this.state.showLoadMore = false;
		this.state.runSearch = false;

		this.loadMore = this.loadMore.bind(this);
		this.handleKeywordSubmit = this.handleKeywordSubmit.bind(this);
		this.runSearch = this.runSearch.bind(this);
		this.setFilter = this.setFilter.bind(this);
	}

	loadMore() {
		this.setState({
			page: this.state.page + 1,
			runSearch: true
		});
	}

	handleKeywordSubmit(value){
		this.setState({
			keyword: value,
			page: 1,
			runSearch: true,
			showLoadMore: false,
			filterOptions: this.state.filterOptions.map((item, itemIndex) => {

				item.active = itemIndex === 0 ? true : false;

				return item;
			})
		});

		const { origin, pathname } = window.location;
		const url = `${origin}${pathname}?keyword=${value}`;
		history.replaceState(null, null, url);
	}

	setFilter(selectedIndex){
		this.setState({
			filterOptions: this.state.filterOptions.map((item, itemIndex) => {

				item.active = selectedIndex === itemIndex ? true : false;

				return item;
			}),
			page: 1,
			runSearch: true
		});
	}

	render(){
		const {
			noResultsMessage,
			showLoadMore,
			filterOptions,
			keyword,
			results
		} = this.state;

		return (
			<div className="site-search-app">
				<SearchInputTypeahead
					placeholderText={'Search LathamDrive'}
					submitButton={true}
					onSubmit={this.handleKeywordSubmit}
				/>
				{
					keyword &&
					<div className="site-search-criteria">
						<span className="search-criteria-label">Showing Results for</span>
						<span className="search-criteria-keyword">"{keyword}"</span>
					</div>
				}
				<div className="site-search-grid">
					<div className="grid-left">
						<SiteSearchFilters
							filterOptions={filterOptions}
							onClick={this.setFilter}
						/>
					</div>
					<div className="grid-right">
						{
							results && results.length > 0 && (
								<ul className="search-results">
									{
										results.map(result => <SearchResult {...result} />)
									}
								</ul>
							)
						}
						{
							results && results.length === 0 &&
							<div className="no-results">{ noResultsMessage }</div>
						}
						{

							showLoadMore &&
							<div className="load-more">
								<button className="btn-load-more" onClick={this.loadMore}>Load More</button>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}

	runSearch(){
		const {
			googleCseApiKey: key,
			googleCseId: id,
			keyword,
			filterOptions,
			page
		} = this.state;


		const perPage = 10;
		const startIndex = (page - 1) * perPage + 1;

		const selectedOptions = filterOptions.filter( item => item.active);
		const filterId = selectedOptions[0].filterId;

		if (this.state.searchSystem === 'GSS') {
			this.googleSiteSearch(key, id, keyword, filterId, perPage, startIndex);
		}
		else {
			this.searchBloxSearch(keyword, filterId, perPage, page);
		}

		this.setState({
			runSearch: false
		});
	}

	googleSiteSearch(key, id, keyword, filterId, perPage, startIndex){
		const url = `https://www.googleapis.com/customsearch/v1
			?key=${key}
			&cx=${id}
			&q=${keyword}
			${filterId ? `&hq=more:pagemap:metatags-limitfilter:${filterId}` : ''}
			&num=${perPage}
			&start=${startIndex}`;

		$.get(url, ({items, queries}) => {

			const startIndex = queries.request[0].startIndex;

			this.setState({
				results: startIndex === 1 ? items || [] : this.state.results.concat(items),
				showLoadMore: queries.nextPage
			});
		});
	}

	searchBloxSearch(keyword, filterId, perPage, page){
		const url = `/api/search
			?q=${keyword}
			&s=${filterId}
			&ps=${perPage}
			&p=${page}`;

		$.get(url, (response) => {
			var items = response.results;
			this.setState({
				results: response.currentpage === 1 ? items || [] : this.state.results.concat(items),
				showLoadMore: (response.currentpage !== response.lastpage) && response.hits > 0
			});
		});
	}

	componentDidMount(){
		if (this.state.keyword){
			this.runSearch();
		}
	}

	componentDidUpdate(prevProps, prevState){
		if (prevState.runSearch === false && this.state.runSearch === true){
			this.runSearch();
		}
	}
}

module.exports = SiteSearchApp;
