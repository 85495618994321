import React, { Component, PropTypes } from 'react';
import GridContent from './GridContent';
import GridBtn from './GridBtn';

// TODO: Fix responsive issue where order does not update when resize
// and when loading previous stories
const getContentOrder = (index, clients) => {
	let order = index;

	if (order === -1) {
		return clients.length;
	}

	for (let i = index + 1; i < clients.length; i++){
		if (clients[i].$el.position().left === 0){
			return order;
		} else {
			order = i;
		}
	}

	return order;
};

class ExpandingGrid extends Component {
	constructor(props) {
		super(props);

		this.state = props.initialState;

		this.state.contentOrder = this.state.clients.length;
	}

	getChildContext() {
		const registerBtnPosition = (index, el) => {
			const { clients }  = this.state;

			clients[index].$el = $(el);

			this.setState({
				clients
			});
		};

		const closeCurrent = () => {
			this.setState({
				previousIndex: this.state.currentIndex,
				currentIndex: -1,
				previousUpdated: false,
				currentUpdated: false
			});
		};

		const btnClickHandler = index => {
			const {
				currentIndex,
				clients
			} = this.state;

			if (currentIndex === index) {
				closeCurrent();
			}
			else if (currentIndex === -1){
				this.setState({
					previousIndex: currentIndex,
					currentIndex: index,
					previousUpdated: true,
					currentUpdated: false,
					contentOrder: getContentOrder(index, clients)
				});
			} else {
				this.setState({
					previousIndex: currentIndex,
					currentIndex: index,
					previousUpdated: false,
					currentUpdated: false
				});
			}
		};

		const updatedPrevious = () => this.setState({
			previousUpdated: true,
			contentOrder: getContentOrder(this.state.currentIndex, this.state.clients)
		});

		const updatedCurrent = () => {
			this.setState({ currentUpdated: true });

			const { clients, currentIndex } = this.state;
			const buttonTop = clients[currentIndex].$el.offset().top;
			const offset = $('.edge-top').outerHeight() + $('.global-nav').outerHeight();

			$('html, body').animate({
				scrollTop: buttonTop - offset
			}, 400);
		};

		return {
			btnClickHandler,
			registerBtnPosition,
			updatedCurrent,
			updatedPrevious,
			closeCurrent
		};
	}

	renderButtons () {

		const {
			currentIndex,
			previousIndex,
			previousUpdated,
			currentUpdated
		} = this.state;

		return this.state.clients.map( ({ imageUrl, clientId }, index) => (
			<GridBtn { ...{
				imageUrl,
				clientId,
				index,
				currentIndex,
				previousIndex,
				previousUpdated,
				currentUpdated
			} } key={clientId}/>
		));
	}

	_loadMore(){
		const { nextPageUrl, clients } = this.state;

		$.get(nextPageUrl).then( data => {

			this.setState({
				clients: clients.concat(data.clients),
				nextPageUrl: data.nextPageUrl,
				currentPageUrl: data.currentPageUrl
			});
		});
	}

	render() {

		const {
			clients,
			contentOrder,
			currentIndex,
			previousIndex,
			previousUpdated,
			nextPageUrl
		} = this.state;

		return (
			<section className="expanding-grid">
				<div className="grid-items">
					{this.renderButtons()}
					<GridContent { ...{
						clients,
						contentOrder,
						currentIndex,
						previousIndex,
						previousUpdated
					} } />
				</div>

				{
					nextPageUrl
					?
					<div className="load-more-container">
						<button
							className="btn-load-more"
							onClick={this._loadMore.bind(this)}
						>
							See all Stories
						</button>
					</div>
					:
					undefined
				}
			</section>
		);
	}

	componentDidMount (){
		this._handleResize = ()=> {
			const { currentIndex, clients } = this.state;

			this.setState({
				contentOrder: getContentOrder(currentIndex, clients)
			});
		};

		$(window).resize( this._handleResize );
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.currentPageUrl !== this.state.currentPageUrl) {

			const { origin } = window.location;
			const url = `${origin}${this.state.currentPageUrl}`;
			history.replaceState(null, null, url);
		}
	}

	componentWillUnmount (){
		$(window).off('resize', this._handleResize);
	}
};

ExpandingGrid.childContextTypes = {
	btnClickHandler: PropTypes.func,
	registerBtnPosition: PropTypes.func,
	updatedCurrent: PropTypes.func,
	updatedPrevious: PropTypes.func,
	closeCurrent: PropTypes.func
};


export default ExpandingGrid;
