import React, { PropTypes, Component } from 'react';
import classnames from 'classnames';

class DefinitionTerm extends Component {
	constructor(props){
		super(props);
	}

	render(){
		let {
			id,
			term,
			isHidden
		} = this.props;

		return (
			<dt
				className={classnames({
					'results-term': true,
					'is-hidden': isHidden
				})}
				key={'term' + id}
				ref={ref=> this._ref = ref}
			>
				{term}
			</dt>);
	}

	scrollToRef(){
		const {
			id,
			scrollToTermId
		} = this.props;

		if (id === scrollToTermId){
			const $component = $(this._ref);
			const $nav = $('.global-nav');

			$('html, body').animate({
				scrollTop: $component.offset().top - $nav.position().top - $nav.outerHeight()
			}, 500);
		}
	}

	componentDidMount(){
		this.scrollToRef();
	}

	componentDidUpdate(){
		this.scrollToRef();
	}
}

DefinitionTerm.propTypes = {
	term: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default DefinitionTerm;
