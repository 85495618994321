import React, {Component} from 'react';
import SearchInputTypeahead from './../common/SearchInputTypeahead';
import ResultsDefinitionList from './../common/ResultsDefinitionList';
import StageSelect from './StageSelect';

import updatePageTitle from './../helpers/updatePageTitle';

const filterCollection = (collection, selectedStage)=> {
	const results = collection.filter(item => {
		return item.stageId.some( ({ id}) => id === selectedStage);
	});

	return results;
};


const filterStage = (stages, selectedStage) => {
	let results = [];

	stages.forEach((item)=>{
		if (item.stageId.toString().indexOf(selectedStage) !== -1){
			results.push(item);
		}
	});

	return results;
};

class FaqSearch extends Component {
	constructor(props){
		super(props);

		this.state = props.initialState;

		// TODO: Improve error handling - crashes when being rendered server side without data
		this.state.results = filterCollection(this.state.dictionary, this.state.selectedStage.stageId);
	}

	toggleFilters(){
		this.setState({
			showFilterOptions: !this.state.showFilterOptions,
			scrollToTermId: ''
		});
	}

	filterEntriesByStage(stageId){
		this.setState({
			results: filterCollection(this.state.dictionary, stageId),
			showFilterOptions: false,
			selectedStage: filterStage(this.state.stages, stageId)[0],
			inputValue: '',
			scrollToTermId: ''
		});
	}

	filterTypeahead(event){
		const value = event.target.value.toLowerCase();
		let results = [];

		if (value.length >= 3){
			this.state.dictionary.forEach((entry)=>{
				if (entry.term.toLowerCase().indexOf(value) !== -1){
					results.push(entry);
				}
			});
		}

		this.setState({
			typeaheadResults: results,
			inputValue: value,
			scrollToTermId: ''
		});
	}


	filterEntiesByTypeaheadSelect(id){
		let stageId;

		const dictionary = this.state.dictionary;

		for (let i = 0; i < dictionary.length; i++){
			if (dictionary[i].id === id) {
				stageId = dictionary[i].stageId[0].id;
				break;
			}
		}

		this.setState({
			results: filterCollection(dictionary, stageId),
			selectedStage: filterStage(this.state.stages, stageId)[0],
			typeaheadResults: [],
			scrollToTermId: id
		});
	}

	filterEntiesByTextInput(value){

		if (value.length === 0) {
			return;
		}

		const dictionary = this.state.dictionary;

		const results = dictionary.map((entry)=>{
			if (entry.term.toLowerCase().indexOf(value) !== -1){
				return {
					...entry,
					isHidden: false
				};
			} else {
				return {
					...entry,
					isHidden: true
				};
			}
		});

		this.setState({
			results: results,
			selectedStage: '',
			typeaheadResults: [],
			scrollToTermId: ''
		});
	}

	render(){
		let {
			placeholderText,
			typeaheadResults,
			inputValue,
			results,
			stages,
			selectedStage,
			showFilterOptions,
			noResultsMessage,
			scrollToTermId
		} = this.state;

		const renderHiddenLinks = function() {
			return stages.map( ({stageId}) => {
				const url = `/resources/faq?stage=${stageId.toLowerCase()}`;
				return (<a href={url} key={stageId}></a>);
			});
		};

		return (
			<div className='faq-search'>
				<SearchInputTypeahead
					{...{
						filterEntities: this.filterEntiesByTypeaheadSelect.bind(this),
						filterTypeahead: this.filterTypeahead.bind(this),
						inputValue,
						placeholderText,
						typeaheadResults,
						submitButton: true,
						onSubmit: this.filterEntiesByTextInput.bind(this)
					}}
				/>
				<div className='faq-results-grid'>
					<div className="left-side">
						<StageSelect {
							...{
								stages,
								selectedStage,
								showFilterOptions,
								filterEntriesByStage: this.filterEntriesByStage.bind(this),
								toggleFilters: this.toggleFilters.bind(this)
							}
						}/>
					</div>
					<div className="right-side">
						<ResultsDefinitionList
							results={results}
							noResultsMessage={noResultsMessage}
							scrollToTermId={scrollToTermId}
						/>
					</div>
				</div>
				<div className="links-for-seo" style={{display: 'none'}}>
					{ renderHiddenLinks() }
				</div>
			</div>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		const currentStage = this.state.selectedStage;
		const currentStageId = currentStage && currentStage.stageId;

		const prevStage = prevState.selectedStage;
		const prevStageId = prevStage && prevStage.stageId;

		if (currentStageId !== prevStageId) {
			const { origin } = window.location;
			const query = currentStage
				? `?stage=${currentStageId}`
				: '';
			const url = `${origin}/resources/faq${query}`;

			history.replaceState(null, null, url);
		}


		// Update the page title if there is a letter selected.
		const prevStageName = prevState.selectedStage ? prevState.selectedStage.name : '';
		const currStageName = this.state.selectedStage ? this.state.selectedStage.name : '';

		if (prevStageName !== currStageName){

			const title = currStageName
				? `${this.state.pageTitle} - ${currStageName}`
				: `${this.state.pageTitle}`;

			updatePageTitle(title);
		}
	}
};

export default FaqSearch;
