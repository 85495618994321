import React, {PropTypes} from 'react';
import classnames from 'classnames';

const ContentCard = ({data})=>{

	const {
		url,
		author,
		date,
		industry,
		tileImgUrl,
		contentType,
		stageSelectClass,
		title,
		iconImgUrl,
		isEvent,
		stageInfo
	} = data;

	const renderStages = ()=> {

		return (
			<ul className={classnames(
				{
					'stage-visual': true,
					[stageSelectClass]: stageSelectClass,
					'is-hidden': isEvent
				})
			}>
				{ stageInfo && stageInfo.map((stage, index)=> (
					<li className="stage-visual-circle" key={index}>
						<span className="stage-visual-label">{stage}</span>
					</li>
				))}
			</ul>
		);
	};

	return (
		<div className="content-card">
			<a className="card-link" href={url}>
				<div className="card-industry">{industry}</div>
				<div
					className="card-background"
					style={{'backgroundImage': `url(${tileImgUrl})`}}>
				</div>
				<div className="card-details">
					{ iconImgUrl && (<img src={iconImgUrl} className="content-type-icon" alt="Content Type Icon"/>) }
					<div className="card-content-type">{contentType}</div>
					<div className="card-title">{title}</div>
					<time className="card-publication-date">{date}</time>
					{ author && (<div className="content-author">By {author}</div>) }
					{ renderStages() }
				</div>
			</a>
		</div>
	);
};

ContentCard.propTypes = {
	data: PropTypes.object.isRequired
};

module.exports = ContentCard;
