let $module,
	$jumpLinkTrigger,
	$jumpLinkDestinations,
	$globalNav,
	$body;

$module = $('.jump-links');

if ($module.length){

	$jumpLinkTrigger = $module.find('.jump-link');
	$jumpLinkDestinations = $('.jump-link-destination');
	$body = $('html, body');
	$globalNav = $('.global-nav');

	$jumpLinkTrigger.click(function(){
		const index = $jumpLinkTrigger.index(this);
		const $destination = $jumpLinkDestinations.eq(index);

		const offset = $globalNav.offset().top + $globalNav.outerHeight();


		$body.animate({
			scrollTop: $destination.offset().top - offset
		}, 200,
			// 'easeInOutExpo'
		);
	});
}

