import React, {PropTypes} from 'react';
import classnames from 'classnames';

const StageSelect = ({
		stages,
		selectedStage,
		toggleFilters,
		showFilterOptions,
		filterEntriesByStage
	})=>(

	<div className={classnames(
			'stage-select',
			{'show-filter-options': showFilterOptions}
		)}>
		<span className='stage-select-prompt'>Stage of Development:</span>
		<div
			className='current-selection'
			onClick={toggleFilters}>
				{selectedStage.name || 'Select A Stage'}
		</div>
		<ul className='stage-select-options'>
			{stages.map(({name, stageId})=>(
				<li className={classnames(
						'stage-select-option',
						{'is-selected': selectedStage.stageId === stageId
					})}
					key={stageId}
					onClick={filterEntriesByStage.bind(this, stageId)}
					>{name}
				</li>
			))}
		</ul>
	</div>
);

StageSelect.propTypes = {
	filterEntriesByStage: PropTypes.func.isRequired,
	stages: PropTypes.array.isRequired,
	toggleFilters: PropTypes.func,
	showFilterOptions: PropTypes.bool
};

export default StageSelect;
