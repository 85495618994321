import React, { PropTypes } from 'react';
import classnames from 'classnames';

const GridContent = (props, {closeCurrent, btnClickHandler}) => {
	const {
		clients,
		contentOrder,
		currentIndex,
		previousIndex,
		previousUpdated
	} = props;

	const getNextPrevIndex = (index) => {

		const totalClients = clients.length;

		return {
			nextIndex: index + 1 === totalClients ? 0 : index + 1,
			prevIndex: index - 1 === -1 ? totalClients - 1 : index - 1
		};
	};

	const gridContentItems = clients.map( (client, index) => {

		const { nextIndex, prevIndex } = getNextPrevIndex(index);
		const prevImageUrl = clients[prevIndex].imageUrlGrayscale;
		const nextImageUrl = clients[nextIndex].imageUrlGrayscale;

		const {
			introduction,
			questions,
			clientQuote,
			quoteAttribution,
			clientId
		} = client;

		const renderQuestions = () => {
			if (questions.length){
				return (
					<div className="story-questions-container">
						{questions.map( ({question, answer}) => {
							return (
								<dl className="story-question-answer">
									<dt className="story-question">{question}</dt>
									<dd className="story-answer">{answer}</dd>
								</dl>
							);
						})}
					</div>
				);
			}
		};

		const renderQuote = () => {
			if (clientQuote){
				return 	(
					<blockquote className="client-blockquote">
						<p className="client-quote">{clientQuote}</p>
						<footer className="quote-attribution">{quoteAttribution}</footer>
					</blockquote>
				);
			}
		};

		const isVisible = (index) => {
			if (!previousUpdated && index === previousIndex){
				return true;
			} else if (previousUpdated && index === currentIndex) {
				return true;
			} else {
				return false;
			}
		};

		return (
			<li
				className={classnames({
					'grid-content-container': true,
					'is-selected': isVisible(index)
				})}
				key={`content-${clientId}`}
			>
				<div className="left-container">
					<button
						className="btn-prev-item"
						onClick={()=> btnClickHandler(getNextPrevIndex(index).prevIndex)}
					>
						<span className="prompt">Previous Story</span>
						<span className="arrow"></span>
						<img className="image" src={prevImageUrl} alt="Previous Client Icon"/>
					</button>
				</div>
				<div className="middle-container">
					<div className="story-intro">{introduction}</div>

					{ renderQuestions() }

					{ renderQuote() }

				</div>
				<div className="right-container">
					<button
						className="btn-next-item"
						onClick={()=> btnClickHandler(getNextPrevIndex(index).nextIndex)}
					>
						<span className="prompt">Next Story</span>
						<img className="image" src={nextImageUrl} alt="Next Client Icon"/>
						<span className="arrow"></span>
					</button>
				</div>
				<button className="btn-close-container" onClick={closeCurrent}></button>
			</li>
		);
	});

	return (
		<ul
			className="grid-content"
			style={{
				'-ms-flex-order': contentOrder,
				order: contentOrder
			}}
		>
			{gridContentItems}
		</ul>
	);
};

GridContent.contextTypes = {
	closeCurrent: PropTypes.func.isRequired,
	btnClickHandler: PropTypes.func.isRequired
};


export default GridContent;
