import React, {PropTypes} from 'react';
import SearchFilterType from './SearchFilterType';

const SearchFilters = ({searchFilters, toggleStageInformation})=> {

	const renderSearchFilterTypes = ({label, modifierClass, options, isStage}, tabIndex)=> (
		<SearchFilterType
			label={label}
			modifierClass={modifierClass}
			options={options}
			isStage={isStage}
			tabIndex={tabIndex}
			key={label}
			toggleStageInformation={toggleStageInformation}
		/>
	);

	return (
		<div className='search-filters'>
			<div className='search-filter-types'>
				<span className='search-filter-prompt'>Filter:</span>
				{searchFilters.map(renderSearchFilterTypes)}
			</div>
		</div>
	);
};

SearchFilters.propTypes = {
	searchFilters: PropTypes.array.isRequired
};

module.exports = SearchFilters;
