let $module,
	$buttons;

$module = $('.advice-accordion');

if ($module.length){
	$buttons = $module.find('.accordion-button');

	$buttons.click(function(){
		const $component = $(this).closest('.advice-accordion');

		$component.toggleClass('is-expanded');
		$component.find('.accordion-content').slideToggle({
			duration: 200
			// easing: 'easeInOutExpo'
		});
	});
}
