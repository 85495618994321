export default function updatePageTitle (newTitle){
	const $title = $('head title');
	const title = $title.text().split(' | ');

	if (newTitle) {
		if (title.length === 3) {
			title.shift();
		}
		title.unshift(newTitle);
	} else {
		if (title.length === 3) {
			title.shift();
		}
	}

	$title.text(title.join(' | '));
}
