import React, {PropTypes} from 'react';
import classnames from 'classnames';

const SearchCriteria = ({label, criteriaId, clear}, {toggleCriteria, clearAll}) => {

	let handleClick = () => {
		clear ? clearAll() : toggleCriteria({label, criteriaId});
	};

	return (
		<li className='search-criteria-container'>
			<button
				className={ classnames({
					'search-criteria': true,
					'clear-criteria': clear
				})}
				onClick={handleClick}>{label}</button>
		</li>
	);
};

SearchCriteria.propTypes = {
	label: PropTypes.string.isRequired,
	criteriaId: PropTypes.string.isRequired
};

SearchCriteria.contextTypes = {
	toggleCriteria: PropTypes.func.isRequired,
	clearAll: PropTypes.func.isRequired
};

module.exports = SearchCriteria;
