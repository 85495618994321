const $module = $('.book-of-jargon-term-popup');

if ($module.length){
	const $termNameContainer = $module.find('.term-name');
	const $definitionContainer = $module.find('.term-definition');
	const $btnClose = $module.find('.btn-close-definition');
	const $main = $('.main');

	const popupWidth = 400;
	const showClass = 'show-popup';

	let $currentElement;

	const positionPopup = ()=> {

		if (!$currentElement){
			return;
		}

		const { top, left } = $currentElement.offset();
		const height = $currentElement.height();
		const width = $currentElement.width();
		const mainWidth = $main.width();
		const mainLeft = $main.offset().left;

		let leftPosition = left + (width / 2) - (popupWidth / 2);
		let rightPosition = 'auto';

		if (mainWidth < popupWidth){
			leftPosition = mainLeft;
		} else {
			if (leftPosition < mainLeft) {
				leftPosition = mainLeft;
			} else if (leftPosition + popupWidth > mainWidth){
				leftPosition = 'auto';
				rightPosition = mainLeft;
			}
		}

		$module.css({
			top: top + height,
			left: leftPosition,
			right: rightPosition,
			width: popupWidth
		});
	};
	$main.on('click', '.book-of-jargon-term', function(){
		const previousElement = $currentElement ? $currentElement[0] : null;

		$currentElement = $(this);
		const id = $currentElement.data('term-id');


		$.get(`/api/book-of-jargon-popup?termid=${id}`)
			.then( (results) => {
				const { term, definition } = results[0];
				const shouldToggle = !($currentElement[0] !== previousElement && $module.hasClass(showClass));

				$definitionContainer.html(definition);
				$termNameContainer.text(term);

				positionPopup();

				if (shouldToggle) {
					$module.toggleClass(showClass);
				}
			});
	});

	$btnClose.click(() => $module.removeClass(showClass));

	$(window).resize(positionPopup);
}
