import React, {PropTypes} from 'react';
import classnames from 'classnames';
import BtnFilterLabel from './BtnFilterLabel';
import FilterCheckbox from './FilterCheckbox';

const SearchFilterType = ({label, tabIndex, modifierClass, options, isStage, toggleStageInformation}, {getTabState})=> {
	const classes = ()=> {
		return classnames(
			'search-filter-slide-down',
			{'is-selected': getTabState() === tabIndex}
		);
	};

	const renderFilterGrid = ()=> (
		<div className={classes()}>
			<div className='width-wrapper'>
				<div className='filter-grid'>
					<ul className='filter-list'>
						{options.map((option) => (
							<FilterCheckbox
								{ ...{...option, key: option.criteriaId }}
							/>
						))}
					</ul>
				</div>
			</div>
		</div>
	);

	const renderFilterStages = ()=> (
		<div className={classes()}>
			<div className='width-wrapper filter-stages-container'>
				<ul className='filter-stages'>
					{options.map((option, index, collection)=>(
						<FilterCheckbox
							index={index}
							total={collection.length}
							label={option.label}
							criteriaId={option.criteriaId}
							queryField={option.queryField}
							key={option.criteriaId}
						/>
					))}
				</ul>
			</div>
		</div>
	);

	const renderFilters = ()=> ( isStage ? renderFilterStages() : renderFilterGrid() );

	const Button = (<BtnFilterLabel
		modifierClass={modifierClass}
		label={label}
		tabIndex={tabIndex}
		isStage={isStage}
	/>);

	return (
		<div className='search-filter-type'>
			{ isStage
				?
				<div className="stage-filter-container">
					<span className="stage-info-icon" onClick={toggleStageInformation}></span>
					{ Button }
				</div>
				: Button
			}
			{options ? renderFilters(options, isStage, tabIndex) : undefined}
		</div>
	);
};

SearchFilterType.propType = {
	label: PropTypes.string.isRequired,
	tabIndex: PropTypes.number.isRequired,
	modifierClass: PropTypes.string,
	options: PropTypes.array,
	isStage: PropTypes.bool
};

SearchFilterType.contextTypes = {
	getTabState: PropTypes.func.isRequired
};

module.exports = SearchFilterType;
