import React, {PropTypes, Component} from 'react';
import classnames from 'classnames';

class StageInfoOverlay extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			stages,
			header,
			intro,
			selectedStage,
			toggleStageInformation,
			toggleStageDropdown,
			showStageDropdown,
			setSelectedStage,
			showStageInformation
		} = this.props;

		let createHandler = (stage)=>()=>{
			setSelectedStage(stage);
			toggleStageDropdown();
		};

		return (
			<div className={classnames(
				'stage-info-overlay',
				{'show-overlay': showStageInformation}
			)}>
				<div
					className="stage-info-content"
					ref={ ref => this.contentRef = ref }
				>
					<div className="content-header">{header}</div>
					<div className="content-intro width-wrapper">{intro}</div>
					<div className="width-wrapper-wider">
						<div className="content-stage-select">
							<div className="current-stage" onClick={toggleStageDropdown}>{selectedStage.name}</div>
							<ul className={classnames(
									"dev-stages",
									{"show-dropdown": showStageDropdown}
								)}>
								{stages.map((stage)=>{
									let {id, name, journey} = stage;
									return (
										<li className={classnames(
												'dev-stage',
												{'is-selected': id === selectedStage.id}
											)}
											key={id}
											onClick={createHandler(stage)}
										>
											<button
												className="btn-dev-stage"
											>{name}</button>
											<div
												className="dev-stage-journey-full"
												dangerouslySetInnerHTML={{__html: journey}}
											/>
										</li>);
									}
								)}
							</ul>
						</div>
						<ul className="dev-stage-journeys-mobile">
							{stages.map(({id, journey})=>(
								<li className={classnames(
										"dev-stage-journey-mobile",
										{'is-selected': id === selectedStage.id}
									)}
									key={id}
									dangerouslySetInnerHTML={{__html: journey}}
								/>
							))}
						</ul>
					</div>
					<button className="btn-close-overlay" onClick={toggleStageInformation}></button>
				</div>
			</div>
		);
	}

	componentDidMount(){

		$('body').click(({target}) => {
			const ref = this.contentRef;
			const { showStageInformation, toggleStageInformation } = this.props;

			if (showStageInformation){
				if (ref === target){
					return;
				} else if (!$.contains(ref, target)){
					toggleStageInformation();
				}
			}
		});
	}
}

StageInfoOverlay.propTypes = {
	stages: PropTypes.array,
	header: PropTypes.string,
	intro: PropTypes.string,
	selectedStage: PropTypes.object,
	toggleStageInformation: PropTypes.func,
	toggleStageDropdown: PropTypes.func,
	showStageDropdown: PropTypes.bool,
	setSelectedStage: PropTypes.func,
	showStageInformation: PropTypes.bool
};

export default StageInfoOverlay;
