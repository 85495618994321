import React, {PropTypes, Component} from 'react';

class TopicDetails extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			title,
			description,
			linkLabel,
			linkToPractice,
			resourcesTitle
		} = this.props;

		return (
			<div className="width-wrapper topic-details">
				<header className="topic-title">{title}</header>
				{
					description
					?
						<div
							className="topic-description rich-text"
							dangerouslySetInnerHTML={{__html: description}}>
						</div>
					: undefined
				}
				{
					linkToPractice
					? <a href={linkToPractice} className="topic-link" target="_blank">{linkLabel}</a>
					: undefined
				}
				{
					resourcesTitle
					? <header className="resource-title">{resourcesTitle}</header>
					: undefined
				}
			</div>
		);
	}

	componentDidMount() {
		this.context.scrollToTop();
	}
};

TopicDetails.propType = {
	title: PropTypes.string,
	description: PropTypes.string,
	linkLabel: PropTypes.string,
	linkToPractice: PropTypes.string,
	resourcesTitle: PropTypes.string
};

TopicDetails.contextTypes = {
	scrollToTop: PropTypes.func.isRequired
};

module.exports = TopicDetails;
