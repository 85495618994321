let $professionals,
	$filterTabs;

$professionals = $('.practice-leaders .professional-tile');
$filterTabs = $('.filter-tabs');

if ($professionals.length && $filterTabs.length){

	$filterTabs.on('change:option', function(e, {dataId}){

		$professionals.show();

		if (dataId === 'all'){
			return;
		}

		$professionals.filter(function(){

			const locationsId = $(this).data('locations-id');

			return locationsId ? locationsId.indexOf(dataId) === -1 : true;
		})
		.hide();
	});
}
