import React, {PropTypes} from 'react';
import DefinitionTerm from './DefinitionTerm';
import classnames from 'classnames';

const ResultsDefinitionList = ({results, noResultsMessage, scrollToTermId})=> {

	const hasResults = ()=> {
		return results.some(item => !item.isHidden);
	};

	return (
		<dl className="results-definition-list">
			{results.map(({term, definition, id, isHidden})=> ([
				<DefinitionTerm {...{term, id, scrollToTermId, isHidden}} />,
				<dd className={classnames({
						'results-definition': true,
						'rich-text': true,
						'is-hidden': isHidden
					})}
					key={'def' + id}
					dangerouslySetInnerHTML={{__html : definition}}
				/>
			]))}
			{ !hasResults() && noResultsMessage && (
				<span className="no-results-message">{noResultsMessage}</span>
			  )
			}
		</dl>
	);
};

ResultsDefinitionList.propTypes = {
	results: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		definition: PropTypes.string.isRequired,
		term: PropTypes.string.isRequired
	})).isRequired
};

export default ResultsDefinitionList;
