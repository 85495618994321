import React, { PropTypes } from 'react';

const LetterNavigation = ({ prevLetter, nextLetter, filterEntriesByLetter })=> (
	<div className="letter-navigation">
		{prevLetter ?
			<button
				className="letter-nav-button letter-prev"
				onClick={filterEntriesByLetter.bind(this, prevLetter)}
			>{prevLetter}</button>
			:
			undefined
		}
		{nextLetter ?
			<button
				className="letter-nav-button letter-next"
				onClick={filterEntriesByLetter.bind(this, nextLetter)}
			>{nextLetter}</button>
			:
			undefined
		}
	</div>
);

LetterNavigation.propType = {
	prevLetter: PropTypes.string,
	nextLetter: PropTypes.string,
	filterEntriesByLetter: PropTypes.func
};

export default LetterNavigation;
