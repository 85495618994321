import BookOfJargonDictionary from './index.js';
import React from 'react';

const $module = $('#book-of-jargon');

if ($module.length){
	ReactDOM.render(
		<BookOfJargonDictionary initialState={JSON.parse(decodeURI(document.getElementById('initial-state').innerHTML))}/>,
		$module[0]
	);
}
