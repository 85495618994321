const $component = $('.form-drop-down');

if ($component.length){

	$component.each(function(){

		const $this = $(this);
		const $currentValue = $this.find('.current-value');
		const $options = $this.find('.drop-down-options');

		$this.click(()=> $this.toggleClass('show-dropdown'));

		$options.on('click', '.drop-down-option', function(){
			const $option = $(this);

			$currentValue
				.text($option.text())
				.data('value', $option.data('value'));
		});
	});
}
