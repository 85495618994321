'use strict';

var $module,
	$buttons;

$module = $('.accordion-module');

if ($module.length){
	$buttons = $module.find('.btn-accordion-label, .btn-close-accordion');

	$buttons.click(function(){
		var $accordion;

		$accordion = $(this).closest('.accordion-module');

		$accordion.toggleClass('is-expanded');
		$accordion.find('.accordion-content').slideToggle();
	});
}
