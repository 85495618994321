'use strict';

var $module,
	$btnMobileMenu,
	$btnSiteSearch,
	$ssInputForm,
	$ssInput,
	state,
	constants,
	updateView;

$module = $('.global-nav');

if ($module.length){

	$btnMobileMenu = $module.find('.btn-mobile-menu');
	$btnSiteSearch = $module.find('.btn-site-search');
	$ssInput = $module.find('.ss-input');
	$ssInputForm = $module.find('.ss-input-container');

	constants = {
		isClosed: -1,
		siteSearchOpen: 1,
		mobileMenuOpen: 2
	};

	state = {
		current: constants.isClosed,

		update: function(newState){
			if (typeof newState === 'number'){
				this.current = newState;
			} else {
				this.current = constants.isClosed;
			}

			updateView();
		}
	};

	updateView = function(){
		var siteSearchOpenClass,
			mobileMenuOpenClass;

		siteSearchOpenClass = 'show-site-search';
		mobileMenuOpenClass = 'show-mobile-menu';

		$module.removeClass(siteSearchOpenClass).removeClass(mobileMenuOpenClass);

		if (state.current === constants.siteSearchOpen) {
			$module.addClass(siteSearchOpenClass);
			$ssInput.focus();

		} else if (state.current === constants.mobileMenuOpen) {
			$module.addClass(mobileMenuOpenClass);
		}
	};

	$btnMobileMenu.click(function(){
		if (state.current === constants.mobileMenuOpen){
			state.update(constants.isClosed);
		} else {
			state.update(constants.mobileMenuOpen);
		}
	});

	$btnSiteSearch.click(function(){
		if (state.current === constants.siteSearchOpen){
			state.update(constants.isClosed);
		} else {
			state.update(constants.siteSearchOpen);
		}
	});

	$ssInputForm.submit(function(event){
		event.preventDefault();

		const value = $ssInput.val();

		if (value.trim().length){
			window.location = `/search?keyword=${value}`;
		}
	});

	$ssInput.keyup(function(){
		if ($ssInput.val().length){
			$ssInputForm.addClass('hide-ie-placeholder');
		} else {
			$ssInputForm.removeClass('hide-ie-placeholder');
		}
	});
}
