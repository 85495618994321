import React, { Component } from 'react';
import classnames from 'classnames';


class SiteSearchFilters extends Component {
	constructor(props){
		super(props);

		this.state = { showFilterOptions: false };
		this.toggleFilters = this.toggleFilters.bind(this);
	}

	toggleFilters({target}) {
		this.setState({
			showFilterOptions: $(target).hasClass('btn-site-search-filter')
				? false
				: !this.state.showFilterOptions
		});
	}

	render() {
		const {
			filterOptions = [],
			onClick
		} = this.props;

		const { showFilterOptions } = this.state;

		return (
			<div className={classnames(
					'site-search-filters',
					{'show-filter-options': showFilterOptions}
				)}>
				<span className='filter-select-prompt'>Filter Results:</span>
				<div
					className='current-selection'
					onClick={this.toggleFilters}>
						{filterOptions.filter(({active}) => active)[0].label}
				</div>
				<ul className="site-search-filter-options">
					{
						filterOptions.map( ({ label, active }, index) => {
							return (
								<li className="site-search-filter" key={label}>
									<button
										className={classnames({
											'btn-site-search-filter': true,
											'is-selected': active
										})}
										key={index}
										onClick={(event)=>{
											this.toggleFilters(event);
											onClick(index);
										}}
									>
										{label}
									</button>
								</li>
							);
						})
					}
				</ul>
			</div>
		);
	}
}

export default SiteSearchFilters;
