'use strict';

import _ from 'lodash';
var degToRad = require ('./helpers').degToRad;

var Node,
	StageNode,
	TopicNode;


Node = function(config, moduleSettings){
	this.name = config.name;
	this.settings = moduleSettings;
};

Node.prototype.unfixPosition = function(){
	this.x = this.fx ? this.fx : this.x;
	this.y = this.fy ? this.fy : this.y;
	this.fx = null;
	this.fy = null;
};

// --------------------------------------------------------

var calculateStartingPositionSinCurve = function(stageNodeRadius, index, total, centerX, centerY){
	var unadjustedX,
		unadjustedY,
		x,
		y,
		middleIndex,
		halfPi,
		position;

	halfPi= Math.PI / 2;
	middleIndex = Math.floor((total) / 2);
	position = index - middleIndex;

	unadjustedY = stageNodeRadius * Math.sin(position * halfPi) * -1;
	unadjustedX = stageNodeRadius * position * Math.SQRT2;

	x = unadjustedX + centerX;
	y = centerY - unadjustedY;

	return {
		x: x,
		y: y,
		// Unadjusted values will be used later to calculate
		// starting positions of Topic Nodes
		unadjustedX: unadjustedX,
		unadjustedY: unadjustedY
	};
};

var calculateStartingPosition = function(circleRadius, index, total, centerX, centerY){
	var offset,
		angleStep,
		angle,
		unadjustedX,
		unadjustedY,
		x,
		y;

	offset = degToRad(90);
	angleStep = degToRad(360 / total);

	angle = offset + angleStep * index * -1;

	// SOHCAHTOA - Assumes center (0, 0) is in center of circle
	unadjustedX = Math.cos(angle) * circleRadius;
	unadjustedY = Math.sin(angle) * circleRadius;

	// Adjust to the center offset; (0, 0) position of SVG is top left corner of page
	x = unadjustedX + centerX;
	y = centerY - unadjustedY;

	return {
		x: x,
		y: y,
		// Unadjusted values will be used later to calculate
		// starting positions of Topic Nodes
		unadjustedX: unadjustedX,
		unadjustedY: unadjustedY,
		angle: angle
	};
};


StageNode = function(config, moduleSettings, options, isCurve){

	var startingPositions;

	Node.call(this, config, moduleSettings, options);

	this.isStageNode = true;
	this.stageId = config.stageId;
	this.radius = this.settings.nodes.stageNodeRadius;


	if (isCurve) {
		startingPositions = calculateStartingPositionSinCurve(
			this.settings.nodes.stageNodeRadiusActive,
			options.index,
			options.total,
			this.settings.centerX(),
			this.settings.centerY());
	} else {
		startingPositions = calculateStartingPosition(
			this.settings.nodes.stageNodeDistanceFromCenter,
			options.index,
			options.total,
			this.settings.centerX(),
			this.settings.centerY());
	}

	this.x = startingPositions.x;
	this.y = startingPositions.y;
	this.fx = this.x;
	this.fy = this.y;

	this.settings.nodes.stageNodeStartPositions[this.stageId] = startingPositions;
};

StageNode.prototype = Object.create(Node.prototype);
StageNode.prototype.constructor = StageNode;

StageNode.prototype.updateActiveState = function(){
	this.isActive = this.stageId  === this.settings.state.activeStageId;
};

StageNode.prototype.getTargetRadius = function(){
	return this.isActive ? this.settings.nodes.stageNodeRadiusActive : this.settings.nodes.stageNodeRadius;
};

// --------------------------------------------------------

var calculateStartingPositionTopicNodes = function(stageNodes, relatedStages, centerX, centerY){

	var stageNode,
		isSharedTopic,
		x,
		y;

	isSharedTopic = relatedStages.length > 1;
	stageNode = stageNodes[relatedStages[0]];

	if (!stageNode){
		return;
	}

	x = isSharedTopic ? stageNode.unadjustedX * 0.2 : stageNode.unadjustedX * 0.6;
	y = isSharedTopic ? stageNode.unadjustedY * 0.2 : stageNode.unadjustedY * 0.6;

	// Adjust to the center offset; (0, 0) position of SVG is top left corner of page
	x = x + centerX;
	y = centerY - y;

	return {
		x: x,
		y: y
	};
};

TopicNode = function(config, moduleSettings, options){

	var startingPositions;

	Node.call(this, config, moduleSettings, options);

	this.isTopicNode = true;
	this.relatedStages = _.clone(config.relatedStages);
	this.radius = this.settings.nodes.topicNodeRadius;

	startingPositions = calculateStartingPositionTopicNodes(
		this.settings.nodes.stageNodeStartPositions,
		this.relatedStages,
		this.settings.centerX(),
		this.settings.centerY());

	this.x = startingPositions.x;
	this.y = startingPositions.y;
	this.url = config.url;
};

TopicNode.prototype = Object.create(Node.prototype);
TopicNode.prototype.constructor = TopicNode;

TopicNode.prototype.updateActiveState = function(){
	this.isActive = this.relatedStages.indexOf(this.settings.state.activeStageId) !== -1;
};

TopicNode.prototype.getTargetRadius = function(){
	return this.isActive ? this.settings.nodes.topicNodeRadiusActive : this.settings.nodes.topicNodeRadius;
};

// --------------------------------------------------------

module.exports = {
	StageNode: StageNode,
	TopicNode: TopicNode
};
