'use strict';

var helpers = require('./helpers'),
	StageNode = require('./nodeClass').StageNode,
	TopicNode = require('./nodeClass').TopicNode;

var data,

	// Settings
	settings,
	nodeData,
	stageData,
	topicData,

	// Selectors
	$module,
	svg,
	nodes,
	stageNodes,
	topicNodes,

	simulation,

	// Functions
	setForce,
	setViewBox,
	initialize;

$module = $('#development-stages');

if ($module.length){
	data = JSON.parse(document.getElementById('stage-panel-data').innerHTML);

	require('./mobile');

	settings = {

		width: 1200,
		height: 700,
		xOffset: 0,
		yOffset: 0,

		nodes: {
			stageNodeRadius: 100,
			stageNodeRadiusActive: 120,

			stageNodeDistanceFromCenter: 250,

			topicNodeRadius: 30,
			topicNodeRadiusActive: 55,

			stageNodeStartPositions: {}
		},

		force: {
			collisionForce: d3.forceCollide().radius(function(d) { return d.radius + settings.force.collisionBuffer; }).iterations(2).strength(0.2),
			collisionBuffer: 3,
			centerForceStrength: 0.005
		},

		state: {
			activeStageId: -1
		},

		promptYPosition: 120,

		// CenterX of Nodes in SVG
		centerX: function(){
			return (this.width / 2) + this.xOffset;
		},

		// CenterY of Nodes in SVG
		centerY: function(){
			return (this.height / 2) + this.yOffset;
		}
	};


	// ---------------- Get Data ------------------------

	stageData = data.stages.map(function(stage, index, collection){
		return new StageNode(stage, settings, {index: index, total: collection.length}, true);
	});

	topicData = data.topics.map(function(topics){
		return new TopicNode(topics, settings);
	});

	nodeData = stageData.concat(topicData);


	// ---------------- Functions ------------------------

	setForce = function(){
		simulation
			.force('X', d3.forceX().strength(settings.force.centerForceStrength).x(settings.centerX()))
			.force('Y', d3.forceY().strength(settings.force.centerForceStrength * 5).y(settings.centerY()));
	};

	setViewBox = function(){
		svg.attr('viewBox', '0 0 ' + settings.width + ' ' + settings.height);
	};

	// ---------------- Initialize ------------------------

	initialize = function(){

		// Create Stage and Topic Nodes
		nodes = svg.selectAll('g')
			.data(nodeData)
			.enter()
			.append('g')
			.classed('stage-node', function(d){ return d.isStageNode; })
			.classed('topic-node', function(d){ return d.isTopicNode; });

		// Create Circles
		nodes.append('circle')
			.style('opacity', 0)
			.attr('r', function(d){ return d.radius; });

		// Create Labels
		nodes.append('text')
			.style('opacity', 0)
			.attr('dy', 0.5)
			.each(function(d){

				var node,
					width,
					words,
					word,
					line,
					lineNumber,
					lineHeight,
					y,
					dy,
					tspan,
					height,
					adjustment;

				// getComputedTextLength doesn't take into account font size so use this number to adjust.
				// this is an experimentally derived number (add text to see when the words breaks to the next line)
				adjustment = 1.6;
				node = d3.select(this);

				if (d.isTopicNode){
					node.style('font-size', '15px');
					width = settings.nodes.topicNodeRadiusActive;
				} else {
					width = settings.nodes.stageNodeRadius;
				}

				words = d.name.split(/\s+/).reverse(),
				line = [],
				lineNumber = 0,
				lineHeight = 1.1;
				y = node.attr("y") || 0,
				dy = parseFloat(node.attr("dy")),
				tspan = node.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

				while (word = words.pop()) {
					line.push(word);
					tspan.text(line.join(" "));

					if (tspan.node().getComputedTextLength() > width * adjustment) {
						// Avoid blank lines
						if (tspan.text() !== word) {
							line.pop();
							tspan.text(line.join(" "));
							line = [word];
							tspan = node.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
						} else {
							if (words.length) {
								line = [];
								tspan = node.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em");
							}
						}
					}
				}

				// Vertically center the content
				height = node.node().getBBox().height;
				node.attr("transform", "translate( 0," + height / -3 + ")");
			});

		// Top Line
		nodes
			.filter(function(d){ return d.isStageNode; })
			.append('line')
			.style('opacity', 0)
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', function(d){return d.radius * -.5;})
			.attr('y2', function(d){return d.radius * -.5;});

		// Bottom Line
		nodes
			.filter(function(d){ return d.isStageNode; })
			.append('line')
			.style('opacity', 0)
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', function(d){return d.radius * .5;})
			.attr('y2', function(d){return d.radius * .5;});

		// Set Initial Position of Stage Nodes (center)
		stageNodes = nodes
			.filter(function(d){ return d.isStageNode; })
			.attr('transform', function(){
				return 'translate(' + settings.centerX() + ',' + settings.centerY() + ')';
			});

		// Set Initial Position of Topic Nodes
		topicNodes = nodes
			.filter(function(d){ return d.isTopicNode; })
			.attr('transform', function(d){
				return 'translate(' + d.x + ',' + d.y + ')';
			});

		d3.queue(1)

			.defer(function fadeInStages (callback){

				stageNodes.selectAll('circle')
					.transition()
					.duration(1000)
					.style('opacity', 1)
					.call(helpers.endAll, function moveToNextSequence(){
						callback(null);
					});
			})

			.defer(function moveStagesToPosition(callback){

				stageNodes
					.transition()
					.duration(300)
					.delay(function(d, i) { return i*100; })
					.attr('transform', function(d){
						return 'translate(' + d.x + ',' + d.y + ')';
					})
					.call(helpers.endAll, function moveToNextSequence(){
						callback(null);
					});
			})

			.defer(function fadeInStageText(callback){
				var transitionDuration = 300;

				stageNodes.selectAll('text')
					.transition()
					.duration(transitionDuration)
					.style('opacity', 1)
					.call(helpers.endAll, function moveToNextSequence(){
						callback(null);
					});

				stageNodes.selectAll('line')
					.transition()
					.delay(transitionDuration / 2)
					.duration(transitionDuration)
					.style('opacity', .3)
					.attr('x1', function(d){return d.radius * -.5;})
					.attr('x2', function(d){return d.radius * .5;});

			})

			.defer(function setupForces(callback){
				var updateNodes = function(){
					simulation.force('collision', settings.force.collisionForce);
					nodes
						.attr('transform', function(d){ return 'translate(' + d.x+','+ d.y+')'; });
				};

				simulation = d3.forceSimulation()
					.nodes(nodeData)
					.force('collision', settings.force.collisionForce)
					.on('tick', updateNodes);

				setForce();
				callback(null);
			})

			.defer(function bindClickHandlers(callback){

				stageNodes.on('click', function(d){
					d3.queue(1)

						.defer(function showTopicNodesIfNotVisible(cb){

							var transitionDuration,
								isTopicNodesVisible;

							transitionDuration = 500;
							isTopicNodesVisible = settings.state.activeStageId !== -1;

							if (!isTopicNodesVisible) {
								topicNodes
									.selectAll('circle')
									.transition()
									.duration(transitionDuration)
									.style('opacity', 1)
									.call(helpers.endAll, function moveToNextSequence(){
										cb(null);
									});

								topicNodes
									.selectAll('text')
									.transition()
									.duration(transitionDuration);

								$('.stages-intro').addClass('fade-out').slideUp();
								$('#development-stages').addClass('no-intro');

							} else {
								cb(null);
							}
						})

						.defer(function updateCircleRadiusAndRestartSimulation(cb){

							var transitionDuration = 1500;

							settings.state.activeStageId = d.stageId;

							nodes
								.each(function(d){ d.updateActiveState(); d.unfixPosition(); })
								.classed('active-node', function(d){ return d.isActive; })
								.transition()
								.duration(transitionDuration)
								.selectAll('circle')
								.attrTween('r', function(d){
									var i = d3.interpolate(d.radius, d.getTargetRadius());

									return function(t) {
										return d.radius = i(t);
									};
								})
								.call(helpers.endAll, function stopSimulation(){
									simulation.alphaTarget(0);
									cb(null);
								})
								.call(function restartSimulation(){
									simulation.alphaTarget(0.3).restart();
								});

							topicNodes
								.selectAll('text')
								.transition()
								.duration(transitionDuration)
								.style('opacity', function(d) {
									return d.isTopicNode && !d.isActive ? 0 : 1;
								});
						});
				});

				topicNodes.on('click', function(d){
					if (d.isActive) {
						var activeStage = stageData.filter(function(stage){
							return stage.isActive;
						})[0];

						window.location.href = '/resources?topics=' + d.url + '&stages=' + activeStage.stageId;
					}
				});

				callback(null);
			});

		// Setup ripple effect
		$('.stage-node, .topic-node.active-node').on('click', function (event) {

			var $ripple = $('<div></div>'),
				offset = $module.offset(),
				xPos = event.pageX - offset.left,
				yPos = event.pageY - offset.top;

			$ripple
				.appendTo($module)
				.addClass('ripple-effect')
				.css({
					top: yPos,
					left: xPos
				});

			window.setTimeout(function(){
				$ripple.remove();
			}, 500);
		});
	};

}


exports.initialize = function(){
	// Create SVG Element
	svg = d3.select('#development-stages').append('svg');

	setViewBox();

	setTimeout(initialize, 200);
};
