import React, { Component, PropTypes } from 'react';
import classnames from 'classnames';

class GridBtn extends Component {
	constructor(props){
		super(props);
	}

	_handleUpdate(){
		const {
			currentIndex,
			previousIndex,
			previousUpdated,
			currentUpdated,
			index
		} = this.props;

		const {
			updatedCurrent,
			updatedPrevious
		} = this.context;

		const slideDuration = 400;

		if (index === currentIndex && previousUpdated && !currentUpdated) {
			$('.grid-content').slideDown(slideDuration, updatedCurrent);

		} else if (index === previousIndex && !previousUpdated) {
			$('.grid-content').slideUp(slideDuration, updatedPrevious);
		}
	}

	render (){

		const {
			imageUrl,
			clientId,
			index,
			currentIndex,
			previousUpdated
		} = this.props;


		const { btnClickHandler } = this.context;

		return (
			<button
				onClick={ () => btnClickHandler(index) }
				className={classnames({
					'grid-btn': true,
					'is-active': previousUpdated && currentIndex === index,
					'is-inactive': currentIndex !== -1 && currentIndex !== index
				})}
				style={{
					backgroundImage: `url(${imageUrl})`,
					order: index,
					msFlexOrder: index
				}}
				data-client-id={clientId}
				key={`button-${clientId}`}
				ref={ ref => this._el = ref }
			>
			</button>
		);
	}

	componentDidMount (){
		const { index } = this.props;

		this._registerBtnPosition = this.context.registerBtnPosition;

		this._registerBtnPosition(index, this._el);
	}

	componentDidUpdate () {
		this._handleUpdate();
	}
};

GridBtn.contextTypes = {
	btnClickHandler: PropTypes.func.isRequired,
	registerBtnPosition: PropTypes.func.isRequired,
	updatedCurrent: PropTypes.func.isRequired,
	updatedPrevious: PropTypes.func.isRequired
};

export default GridBtn;
