import React, {PropTypes} from 'react';
import classnames from 'classnames';

const AlphaFilter = ({
		activeLetter,
		entriesByLetter,
		filterEntriesByLetter,
		showFilterOptions,
		toggleFilters
	})=>{

	let letters = [];

	for (let i = 0; i < 26; i++){
		letters.push(String.fromCharCode('A'.charCodeAt(0) + i));
	}

	letters.push('0-9');

	return (<div className={classnames('alpha-filter', {'show-options': showFilterOptions})}>
				<div className="prompt">Filter:</div>
				<div
					className="current-option"
					onClick={toggleFilters}
					>{activeLetter ? activeLetter : 'Select Letter'}
				</div>
				<div className="filter-options">
					{letters.map(letter => (
						<button className={classnames(
								'filter-option',
								{'is-active': letter === activeLetter},
								{'has-no-entries': entriesByLetter[letter] === undefined}
							)}
							key={letter}
							onClick={filterEntriesByLetter.bind(this, letter)}
						>{letter}</button>
					))}
				</div>
			</div>
	);
};

AlphaFilter.propTypes = {
	activeLetter: PropTypes.string,
	entriesByLetter: PropTypes.object,
	filterEntriesByLetter: PropTypes.func,
	showFilterOptions: PropTypes.bool,
	toggleFilters: PropTypes.func
};

AlphaFilter.defaultProps = {
	entriesByLetter: {},
	filterEntriesByLetter: function(){},
	showFilterOptions: true
};

export default AlphaFilter;
