import React, {Component}from 'react';
import SearchInputTypeahead from './../common/SearchInputTypeahead';
import AlphaFilter from './AlphaFilter';
import ResultsDefinitionList from './../common/ResultsDefinitionList';
import LetterNavigation from './LetterNavigation';
import updatePageTitle from './../helpers/updatePageTitle';

const getTerms = (terms, validator) => {
	return terms.filter(validator);
};

class BookOfJargonDictionary extends Component {
	constructor(props) {
		super(props);

		this.state = props.initialState;

		const activeLetter = this.state.entriesByLetter[this.state.activeLetter];

		this.state.results = getTerms(this.state.dictionary, item => item.letter === this.state.activeLetter);

		this.state.prevLetter = activeLetter.previous;
		this.state.nextLetter = activeLetter.next;
	}

	toggleFilters(){
		this.setState({showFilterOptions: !this.state.showFilterOptions});
	}

	clearSearch(){
		const letter = 'A';
		const nextLetter = this.state.entriesByLetter[letter];
		const entries = getTerms(this.state.dictionary, item => item.letter === letter);

		this.setState({
			results: entries,
			activeLetter: letter,
			nextLetter: nextLetter.next,
			prevLetter: nextLetter.previous,
			showFilterOptions: false,
			inputValue: ''
		});

		this.scrollToComponent();
	}

	filterEntriesByLetter(letter){
		const nextLetter = this.state.entriesByLetter[letter];
		const entries = getTerms(this.state.dictionary, item => item.letter === letter);

		if (entries === undefined){
			return;
		}

		this.setState({
			results: entries,
			activeLetter: letter,
			nextLetter: nextLetter.next,
			prevLetter: nextLetter.previous,
			showFilterOptions: false,
			inputValue: ''
		});

		this.scrollToComponent();
	}

	filterTypeahead(event){
		const value = event.target.value.toLowerCase();

		let results = [];

		if (value.length >= 3){
			results = getTerms(this.state.dictionary, item => {
				return item.term.toLowerCase().indexOf(value) !== -1;
			});
		}

		this.setState({
			typeaheadResults: results,
			inputValue: value
		});
	}


	filterEntitiesByTypeaheadSelect(id){
		const results = getTerms(this.state.dictionary, item => {
			return item.id === id;
		});

		this.setState({
			results: results,
			activeLetter: '',
			typeaheadResults: []
		});
	}


	filterEntitiesByTextInput(value){
		if (value.length === 0) {
			return;
		}

		const results = getTerms(this.state.dictionary, item => {
			return item.term.toLowerCase().indexOf(value) >= 0;
		});

		this.setState({
			typeaheadResults: [],
			activeLetter: '',
			results,
			searchCriteria: value
		});
	}

	scrollToComponent() {
		const $component = $(this.componentRef);
		const $nav = $('.global-nav');

	    $('html, body').animate({
	        scrollTop: $component.offset().top - $nav.position().top - $nav.outerHeight()
	    }, 500);
	}

	render() {

		const {
			placeholderText,
			typeaheadResults,
			activeLetter,
			showFilterOptions,
			entriesByLetter,
			inputValue,
			results,
			prevLetter,
			nextLetter,
			noResultsMessage
		} = this.state;

		const renderHiddenLinks = function() {
			const keys = Object.keys(entriesByLetter);
			return keys.map( key => {
				const url = `/resources/book-of-jargon?letter=${key.toLowerCase()}`;
				return (<a href={url} key={key}></a>);
			});
		};


		return (
			<div className='book-of-jargon-dictionary' ref={ ref => this.componentRef = ref }>
				<SearchInputTypeahead
					{...{
						filterEntities: this.filterEntitiesByTypeaheadSelect.bind(this),
						filterTypeahead: this.filterTypeahead.bind(this),
						inputValue,
						placeholderText,
						typeaheadResults,
						submitButton: true,
						onSubmit: this.filterEntitiesByTextInput.bind(this),
						clearInput: this.clearSearch.bind(this),
						showClearButton: !activeLetter
					}}
				/>
				<AlphaFilter
					{...{
						activeLetter,
						entriesByLetter,
						filterEntriesByLetter: this.filterEntriesByLetter.bind(this),
						showFilterOptions,
						toggleFilters: this.toggleFilters.bind(this)
					}}
				/>
				{ results && <ResultsDefinitionList results={results} noResultsMessage={noResultsMessage} /> }
				{
					activeLetter && (
						<LetterNavigation
							{...{
								prevLetter,
								nextLetter,
								filterEntriesByLetter: this.filterEntriesByLetter.bind(this)
							}}
						/>
					)
				}
				<div className="links-for-seo" style={{display: 'none'}}>
					{ renderHiddenLinks() }
				</div>
			</div>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.activeLetter !== prevState.activeLetter) {
			const { origin } = window.location;
			const query = this.state.activeLetter
				? `?letter=${this.state.activeLetter.toLowerCase()}`
				: '';
			const url = `${origin}/resources/book-of-jargon${query}`;

			history.replaceState(null, null, url);
		}

		// Update the page title if there is a letter selected.
		if (prevState.activeLetter !== this.state.activeLetter){

			const title = this.state.activeLetter
				? `${this.state.pageTitle} - ${this.state.activeLetter}`
				: `${this.state.pageTitle}`;

			updatePageTitle(title);
		}
	}
}

export default BookOfJargonDictionary;
