import React, {PropTypes} from 'react';
import SearchCriteria from './SearchCriteria';

const SearchCriteriaList = ({items}) => {
	return (
		<div className="width-wrapper-wider">
			<ul className='search-criteria-list'>
				{
					items.map(({label, criteriaId})=>(
						<SearchCriteria label={label} criteriaId={criteriaId} key={criteriaId}
					/>))
				}
				{
					items.length > 1 && (
						<SearchCriteria label={'Clear All'} key={'clear-all'} clear={true}/>
					)
				}
			</ul>
		</div>
	);
};

SearchCriteriaList.propTypes = {
	items: PropTypes.array.isRequired
};

module.exports = SearchCriteriaList;
