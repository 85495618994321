import React from 'react';
import SiteSearch from './SiteSearchApp';

const $module = $('#site-search-component-container');

if ($module.length){
	ReactDOM.render(
		<SiteSearch initialState={JSON.parse(decodeURI(document.getElementById('initial-state').innerHTML))}/>,
		document.getElementById('site-search-component-container')
	);
}
