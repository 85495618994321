import React, {PropTypes, Component} from 'react';
import classnames from 'classnames';

class SearchInputTypeahead extends Component {
	constructor(props){
		super(props);
	}

	render() {
		const {
			filterTypeahead,
			filterEntities,
			placeholderText,
			typeaheadResults,
			submitButton,
			inputValue,
			onSubmit,
			clearInput,
			showClearButton
		} = this.props;

		const renderTypeaheadWithMultipleStages = (stages, id, term) => {
			return stages.map( stage => (
				<li
					className="typeahead-result"
					key={id}
					onClick={filterEntities.bind(this, id)}
				>
					<span className="result-stage">{stage.label}</span>{term}
				</li>
			));
		};

		return (
			<form
				className={classnames(
					'search-input-typeahead',
					{ 'show-typeahead' : typeaheadResults && typeaheadResults.length }
				)}
				onSubmit={ event => {
					event.preventDefault();

					onSubmit && onSubmit(this.inputRef.value);
				}}
			>
				<div className="input-container">
					<input
						ref={ ref => this.inputRef = ref }
						type="text"
						placeholder={placeholderText}
						className="search-input"
						value={inputValue}
						onChange={filterTypeahead}
					/>
					{
						typeaheadResults && typeaheadResults.length > 0 &&
							<ul className="typeahead-results">
								{ typeaheadResults.map(({id, term, stageId}) => {
									if (stageId){
										return renderTypeaheadWithMultipleStages(stageId, id, term);
									} else {
										return (
											<li
												className="typeahead-result"
												key={id}
												onClick={filterEntities.bind(this, id, term)}>{term}</li>
										);
									}
								})}
							</ul>
					}
					{ clearInput && showClearButton &&
						<button
							className='btn-search-clear'
							type='button'
							onClick={clearInput}
						></button>
					}
					{ submitButton && <button className='btn-search-input-submit' type='submit'></button> }
				</div>
			</form>
		);
	}
}

SearchInputTypeahead.propTypes = {
	filterEntities: PropTypes.func,
	onSubmit: PropTypes.func,
	filterTypeahead: PropTypes.func,
	placeholderText: PropTypes.string,
	typeaheadResults: PropTypes.array
};

export default SearchInputTypeahead;
