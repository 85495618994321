import React, {PropTypes} from 'react';
import classnames from 'classnames';

const FilterCheckbox = ({index, total, label, criteriaId, queryField}, {isCriteriaSelected, toggleCriteria, setTabState})=> {
	const handleClick = ()=> {
		toggleCriteria({criteriaId, label, queryField});
		setTabState(0);
	};

	const renderDiagramCircles = ()=> {
		let circles = [];

		for (let i = 0; i<total; i++){
			circles.push((<div
				className={classnames({
					'diagram-circle': true,
					'current-stage': i === index
				})}
				key={i}
			></div>));
		}

		return  (
			<div className='stage-diagram'>
			{circles}
			</div>
		);
	};

	return (
		<li className={classnames({
				'filter-checkbox': true,
				'is-selected': isCriteriaSelected(criteriaId)
			})}
			onClick={handleClick}
		>
			{ total ? renderDiagramCircles() : undefined}
			<span className='filter-label'>{label}</span>
		</li>
	);
};

FilterCheckbox.propTypes = {
	index: PropTypes.number,
	total: PropTypes.number,
	label: PropTypes.string.isRequired,
	criteriaId: PropTypes.string.isRequired
};

FilterCheckbox.contextTypes = {
	isCriteriaSelected: PropTypes.func.isRequired,
	toggleCriteria: PropTypes.func.isRequired,
	setTabState: PropTypes.func
};

module.exports = FilterCheckbox;
