var $nav,
	$trigger,
	state,
	updateView,
	handleScroll;


$nav = $('.global-nav');
$trigger = $('.global-nav-trigger');

if ($nav.length && $trigger.length){

	state = {
		wasPrevActive: false,
		isNowActive: false,
		isNowCrossedTop: false,
		wasPrevCrossedTop: false
	};

	updateView = function(){
		var alternateClassColor,
			alternateClassBg;

		alternateClassColor = 'alternate-color';
		alternateClassBg = 'alternate-bg';

		// Set the link colors if trigger has passed middle of nav
		if (state.isNowActive && !state.wasPrevActive) {
			$nav.addClass(alternateClassColor);
			state.wasPrevActive = true;
		} else if (!state.isNowActive && state.wasPrevActive) {
			$nav.removeClass(alternateClassColor);
			state.wasPrevActive = false;
		}

		// Set the background if trigger has passed top
		if (state.isNowCrossedTop && !state.wasPrevCrossedTop) {
			$nav.addClass(alternateClassBg);
			state.wasPrevCrossedTop = true;
		} else if (!state.isNowCrossedTop && state.wasPrevCrossedTop) {
			$nav.removeClass(alternateClassBg);
			state.wasPrevCrossedTop = false;
		}
	};

	handleScroll = function(){
		var navTop,
			triggerTop;

		navTop = $nav.offset().top;
		triggerTop = $trigger.offset().top;

		state.isNowActive = navTop + $nav.outerHeight() / 2 > triggerTop;
		state.isNowCrossedTop = navTop > triggerTop;

		updateView();
	};

	handleScroll();

	$(window).scroll(handleScroll);

}
