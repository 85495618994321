'use strict';

var helpers = require('./helpers'),
	degToRad = helpers.degToRad,
	StageNode = require('./nodeClass').StageNode,
	TopicNode = require('./nodeClass').TopicNode;

var settings,
	nodes,
	data,
	svg,
	stageData,
	topicsData,
	topicsContainer,
	initialize;

settings = {
	width: 700,
	height: 650,
	heightAfterIntro: 480,
	xOffset: 200,
	yOffset: 10,

	nodes: {
		stageNodeRadius: 100,
		stageNodeRadiusActive: 140,
		stageNodeDistanceFromCenter: 200,
		stageNodeStartPositions: {}
	},

	state: {
		activeStageId: -1
	},

	// CenterX of Nodes in SVG
	centerX: function(){
		return this.width / 2;
	},

	// CenterY of Nodes in SVG
	centerY: function(){
		return this.height / 2;
	}
};

// ---------------- Get Data ------------------------
data = JSON.parse(document.getElementById('stage-panel-data').innerHTML);

stageData = data.stages.map(function(stage, index, collection){
	return new StageNode(stage, settings, {index: index, total: collection.length});
});

topicsData = data.topics.map(function(topics){
	return new TopicNode(topics, settings);
});

// ---------------- Initialize ------------------------

initialize = function(){

	// Create SVG Element
	svg = d3.select('#development-stages-mobile').append('svg')
		.attr('viewBox', '0 0 ' + settings.width + ' ' + settings.height);

	// Create Stage Nodes and Position Nodes in Center
	nodes = svg.selectAll('g')
		.data(stageData)
		.enter()
		.append('g')
		.classed('stage-node', function(d){ return d.isStageNode; })
		.attr('transform', function(){
			return 'translate(' + settings.centerX() + ',' + settings.centerY() + ')';
		});

	// Create Circles
	nodes.append('circle')
		.style('opacity', 0)
		.attr('r', function(d){ return d.radius; });

	// Create Labels
	nodes.append('text')
		.style('opacity', 0)
			.attr('dy', 0.5)
			.each(function(d){

				var node,
					width,
					words,
					word,
					line,
					lineNumber,
					lineHeight,
					y,
					dy,
					tspan,
					height,
					adjustment;

				// getComputedTextLength doesn't take into account font size so use this number to adjust.
				// this is an experimentally derived number (add text to see when the words breaks to the next line)
				adjustment = 1.7;
				node = d3.select(this);
				width = settings.nodes.stageNodeRadius;
				words = d.name.split(/\s+/).reverse(),
				line = [],
				lineNumber = 0,
				lineHeight = 1.2;
				y = node.attr("y") || 0,
				dy = parseFloat(node.attr("dy")),
				tspan = node.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

				while (word = words.pop()) {
					line.push(word);
					tspan.text(line.join(" "));

					if (tspan.node().getComputedTextLength() > width * adjustment) {
						// Avoid blank lines
						if (tspan.text() !== word) {
							line.pop();
						}
						tspan.text(line.join(" "));
						line = [word];
						tspan = node.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
					}
				}

				// Vertically center the content
				height = node.node().getBBox().height;
				node.attr("transform", "translate( 0," + height / -3 + ")");
			});

	topicsContainer = d3.select('.stages-mobile-container .stage-topics-container');

	d3.queue(1)

		.defer(function fadeInStages (callback){

			nodes.selectAll('circle')
				.transition()
				.duration(1000)
				.style('opacity', 1)
				.call(helpers.endAll, function(){
					callback(null);
				});
		})

		.defer(function moveStagesToPosition(callback){

			nodes
				.transition()
				.duration(300)
				.attr('transform', function(d){
					return 'translate(' + d.x + ',' + d.y + ')';
				})
				.call(helpers.endAll, function(){
					callback(null);
				});
		})

		.defer(function fadeInStageNodeText(callback){

			var transitionDuration = 300;

			nodes.selectAll('text')
				.transition()
				.duration(transitionDuration)
				.style('opacity', 1)
				.call(helpers.endAll, function(){
					callback(null);
				});
		})

		.defer(function bindClickHandlers(callback){

			nodes.on('click', function(d){
				var transitionDuration,
					activeIndex,
					calculateMobilePositions;

				transitionDuration = 750;
				settings.state.activeStageId = d.stageId;

				calculateMobilePositions =function (d, i){
					var position,
						offset,
						angleStep,
						angle,
						x,
						y,
						calculateAngleStep;


					if (i === activeIndex){
						d.x = d.getTargetRadius() + d.settings.xOffset;
						d.y = d.settings.centerY() + d.settings.yOffset;
						return;
					}

					position = activeIndex < i ? i - 1 : i;
					offset = degToRad(157.5);

					calculateAngleStep = function(){
						var spanLength,
							radius,
							circumference,
							coveragePercentage,
							step;


						radius = d.settings.nodes.stageNodeRadius + d.settings.nodes.stageNodeRadiusActive;

						circumference = 2 * Math.PI * radius;

						spanLength = d.settings.nodes.stageNodeRadius * 2;
						coveragePercentage = spanLength / circumference;

						step = 2 * Math.PI * coveragePercentage;

						return step;
					};

					angleStep = calculateAngleStep.call(d);
					angle = offset + angleStep * position * -1;

					x = Math.cos(angle) * (d.settings.nodes.stageNodeRadius + d.settings.nodes.stageNodeRadiusActive);
					y = Math.sin(angle) * (d.settings.nodes.stageNodeRadius + d.settings.nodes.stageNodeRadiusActive);

					// Adjust to the center offset; (0, 0) position of SVG is top left corner of page
					d.x = x + d.settings.nodes.stageNodeRadiusActive + d.settings.xOffset;
					d.y = d.settings.centerY() - y + d.settings.yOffset;
				};

				// Fadeout Stages Intro
				$('.stages-intro').addClass('fade-out').slideUp();

				$('#development-stages').addClass('no-intro');

				// Update Active State and Circle Radius
				nodes
					.each(function(d, i){
						d.updateActiveState();
						if (d.isActive){
							activeIndex = i;
						}
					})
					.classed('active-node', function(d){ return d.isActive; })
					.transition()
					.duration(transitionDuration)
					.selectAll('circle')
					.attrTween('r', function(d){
						var i = d3.interpolate(d.radius, d.getTargetRadius());

						return function(t) {
							return d.radius = i(t);
						};
					});

				// Animate Nodes to New Position and Populate Topics List
				nodes
					.each(calculateMobilePositions)
					.transition()
					.duration(transitionDuration)
					.attr('transform', function moveNodesToNewPosition(d){
						return 'translate(' + d.x + ',' + d.y + ')';
					})
					.call(function emptyTopicsListContainer(){

						var transitionDuration = 400;

						// Remove List Items from Container
						topicsContainer.selectAll('li')
							.data([])
							.exit()
							.transition()
							.duration(transitionDuration)
							.style('opacity', 0)
							.remove();

					})
					.call(helpers.endAll, function populateTopicsListContainer(){

						// Adjust viewbox of svg to shorter height to save space on the screen
						svg.attr('viewBox', '0 0 ' + settings.width + ' ' + settings.heightAfterIntro);

						var activeStage = stageData.filter(function(stage){
							return stage.isActive;
						})[0];

						// Append New Topic List Items
						topicsContainer.selectAll('li')
							.data(topicsData)
							.enter()
							.filter(function(d){
								d.updateActiveState();
								return d.isActive;
							})
							.append('li')
							.style('opacity', 0)
							.classed('topic-link-container', true);

						// Append Links To List Items
						topicsContainer.selectAll('li')
							.append('a')
							.text(function(d){ return d.name; })
							.attr('href', function(d){ return '/resources?topics=' + d.url + '&stages=' + activeStage.stageId; });

						// Transition List Items in
						topicsContainer.selectAll('li')
							.transition()
							.duration(500)
							.style('opacity', 1)
							.styleTween('transform', function(){
								var i = d3.interpolate(20, 0);

								return function(t){
									return 'translate3d( 0, ' + i(t) + 'px, 0)';
								};
							});
					});
			});

			callback(null);
		});
};

exports.initialize = () => setTimeout(initialize, 200);
