import React, {PropTypes} from 'react';
import ContentCard from './ContentCard';

const ContentCardList = ({items})=> (
	<div className='content-card-list'>
		<div className='center-wrapper'>

			{ /* TODO: Update Key to unique key per item */}
			{ items.map((data, i)=> (<ContentCard data={data} key={i}/>))}
		</div>
	</div>
);

ContentCardList.propTypes = {
	items: PropTypes.array.isRequired
};

module.exports = ContentCardList;
