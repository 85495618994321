import React from 'react';

const SearchResult = ({link, formattedUrl, htmlTitle, htmlFormattedUrl, htmlSnippet}) => {
	return (
		<li className="search-result">
			<a
				className="search-result-link"
				href={link}
				dangerouslySetInnerHTML={ {__html: htmlTitle} }>
			</a>
			<p
				className="search-result-brief"
				dangerouslySetInnerHTML={ {__html: htmlSnippet} }>
			</p>
			<span
				className="search-result-url"
				dangerouslySetInnerHTML={ {__html: htmlFormattedUrl} }>
			</span>
		</li>
	);
};

export default SearchResult;
