let $module,
	$currentOption,
	$options;

$module = $('.filter-tabs');

if ($module.length){
	$options = $module.find('.drop-down-option');
	$currentOption = $module.find('.current-option');

	$currentOption.click(function(){
		$(this).closest('.filter-tabs').toggleClass('show-drop-down');
	});

	$options.click(function(){
		const activeClass = 'selected-option';
		const $this = $(this);

		$options.removeClass(activeClass);
		$this.addClass(activeClass);
		$currentOption.text($this.text());
		$this.closest('.filter-tabs')
			.removeClass('show-drop-down')
			.trigger('change:option', {dataId: $this.data('locations-id')});
	});
}
