'use strict';

// Invoke the callback function once after all transitions for the selection are complete
// http://stackoverflow.com/questions/10692100/invoke-a-callback-at-the-end-of-a-transition
exports.endAll =  function(transition, callback) {
	if (!callback) callback = function(){};
	if (transition.size() === 0) { callback(); }

	var n = 0;

	transition
		.each(function() { ++n; })
		.on('end', function() { if (!--n) callback.apply(this, arguments); });
};


exports.degToRad = function(deg){
	return Math.PI / 180 * deg;
};
