'use strict';

$(document).ready(function(){
	require('./features/homepage-fullpage-setup');
	require('./features/global-nav-scroll');
	require('./features/accordion-module-triggers');
	require('./features/mobile-menu-site-search-triggers');
	require('./features/jump-links');
	require('./features/filter-tabs');
	require('./features/practice-leaders');
	require('./features/advice-accordion');
	require('./features/init-animation');
	require('./../../shared/react-components/resource-search');
	require('./../../shared/react-components/BookOfJargonDictionary/clientEntry');
	require('./../../shared/react-components/FaqSearch/clientEntry');
	require('./../../shared/react-components/ExpandingGrid/clientEntry');
	require('./features/book-of-jargon-term-popup');
	require('./../../shared/react-components/SiteSearch/clientEntry');
	require('./features/form-drop-down');
	require('./features/contact-us-form');
	require('./features/homepage-stages-tabs');
});
