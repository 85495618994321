import React, {PropTypes, Component} from 'react';
import classnames from 'classnames';
import SearchFilters from './SearchFilters';
import SearchCriteriaList from './SearchCriteriaList';
import ContentCardList from './ContentCardList';
import StageInfoOverlay from './StageInfoOverlay';
import TopicDetails from './TopicDetails';
import updatePageTitle from './../helpers/updatePageTitle';

class ResourceSearchApp extends Component {
	constructor(props) {
		super(props);

		let initialState = props.initialState;

		initialState.tabState = 0;
		initialState.stages.selectedStage = initialState.stages.stages[0];

		this.state = initialState;
	}

	getChildContext() {

		const getTabState = () => this.state.tabState;

		const setTabState = (tabIndex) => {
			this.setState({tabState: getTabState() === tabIndex ? 0 : tabIndex});
		};

		const isCriteriaSelected = (criteriaId) => {

			let found = false;

			this.state.criteria.forEach((item)=>{

				if (found) {
					return;
				}

				if (item.criteriaId === criteriaId){
					found = true;
				}
			});

			return found;
		};

		const toggleCriteria = (criteria) => {

			const newState = {};

			if (this.state.topicDetails) {
				newState.topicDetails = undefined;
			}


			if (isCriteriaSelected(criteria.criteriaId)) {

				const newCriteria = this.state.criteria.filter((item)=>item.criteriaId !== criteria.criteriaId);

				newState.criteria = newCriteria;

				this.setState(newState);
			} else {

				this.state.criteria.push(criteria);

				newState.criteria = this.state.criteria;

				this.setState(newState);
			}

			this._getResults();
		};

		const clearAll = () => {
			this.setState({
				criteria: []
			});

			this._getResults();
		};

		this.scrollToTop = ()=> {
			const $resourceSearch = $('.sign-up');
			const $nav = $('.global-nav');

		    $('html, body').animate({
		        scrollTop: $resourceSearch.offset().top - $nav.position().top - $nav.outerHeight()
		    }, 500);
		};

		return {
			getTabState,
			setTabState,
			toggleCriteria,
			isCriteriaSelected,
			clearAll,
			scrollToTop: this.scrollToTop
		};
	}

	toggleStageInformation() {

		this.setState({
			showStageInformation: !this.state.showStageInformation
		});
	}

	setSelectedStage(stage) {

		let stages = this.state.stages;

		stages.selectedStage = stage;

		this.setState({ stages });
	}

	toggleStageDropdown() {
		this.setState({
			showStageDropdown: !this.state.showStageDropdown
		});
	}

	_getResults(){
		// Run after state is sucessfully updated
		setTimeout(()=>{
			const { criteria } = this.state;

			const query = criteria.map( ({criteriaId, queryField}) => {
				return `${queryField}=${criteriaId}`;
			}).join('&');

			$.get(`/api/advice-events?${query}`).then( ({ results, nextUrl, currentUrl, topicDetails }) => {

				this.setState({
					items: results,
					nextUrl,
					currentUrl,
					topicDetails
				});
			});
		}, 1);
	}

	_loadMore(){
		const { nextUrl, items } = this.state;

		$.get(nextUrl).then( ({ results, nextUrl, currentUrl, topicDetails }) => {

			this.setState({
				items: items.concat(results),
				nextUrl,
				currentUrl,
				topicDetails
			});
		});
	}

	render() {
		let {
			searchFilters,
			criteria,
			items,
			tabState,
			stages,
			showStageInformation,
			showStageDropdown,
			nextUrl,
			topicDetails,
			noSearchResultsMessage
		} = this.state;

		return (
			<div className={ classnames('resource-search-app', {'filters-open': tabState !== 0})}>
				<SearchFilters searchFilters={searchFilters} toggleStageInformation={this.toggleStageInformation.bind(this)} />
				<SearchCriteriaList items={criteria} />
				{
					topicDetails && <TopicDetails {...topicDetails}/>
				}
				<ContentCardList items={items} />
				<StageInfoOverlay {...stages}
					{...{
						toggleStageInformation: this.toggleStageInformation.bind(this),
						setSelectedStage: this.setSelectedStage.bind(this),
						toggleStageDropdown: this.toggleStageDropdown.bind(this),
						showStageInformation,
						showStageDropdown
					}}
				/>
				{
					!items.length && (
						<div className="no-results-message">
							{noSearchResultsMessage}
						</div>
					)
				}
				{
					nextUrl && (
						<div className="load-more-container">
							<button
								className="btn-load-more"
								onClick={this._loadMore.bind(this)}
							>
								Load More
							</button>
						</div>
					)
				}
				{
					items.length > 0 && (
						<div className="back-to-top">
							<button
								className="btn-back-to-top"
								onClick={()=> this.scrollToTop()}
							>
								Back to Top
							</button>
						</div>
					)
				}
			</div>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.currentUrl !== this.state.currentUrl) {

			const { origin } = window.location;
			const url = `${origin}${this.state.currentUrl}`;
			history.replaceState(null, null, url);
		}

		// Update the page title if there is 1 topic detail selected.
		// The topicDetails value only exists if there is only 1 topic detail
		// selected so use the data from that value
		const prevTitle = prevState.topicDetails ? prevState.topicDetails.title : '';
		const currTitle = this.state.topicDetails ? this.state.topicDetails.title : '';

		if (prevTitle !== currTitle){
			updatePageTitle(currTitle);
		}
	}
}

ResourceSearchApp.childContextTypes = {
	getTabState: PropTypes.func,
	setTabState: PropTypes.func,
	toggleCriteria: PropTypes.func,
	isCriteriaSelected: PropTypes.func,
	clearAll: PropTypes.func,
	scrollToTop: PropTypes.func
};

module.exports = ResourceSearchApp;
