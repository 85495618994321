const $component = $('.stages-topics-tabs');

if ($component.length){
	const $buttons = $component.find('.btn-stage-select');
	const activeClassPrefix = 'show-stage-';
	let activeClass = '';

	$buttons.click(function(){
		const buttonIndex = $buttons.index(this);

		if (activeClass){
			$component.removeClass(activeClass);
		} else {
			// Fadeout Stages Intro
			$('.stages-intro').addClass('fade-out').slideUp();
			$('#development-stages').addClass('no-intro');
		}

		activeClass = activeClassPrefix + buttonIndex;
		$component.addClass(activeClass);
	});
}
