const $module = $('.contact-us-form');

if ($module.length){
	const $dropdown = $module.find('.form-drop-down .current-value');

	// Clear default content in textarea
	$('textarea').text('');

	window.recaptchaCallback = (response) => {

		const data = $module.serializeArray().concat({
			name: 'country',
			value: $dropdown.attr('value')
		}).concat({
			name: 'recaptcha',
			value: response
		});

		$.post('/api/contact', data)
			.done(function(data, statusMessage, { status }){
				if (status === 201){
					$module
						.find('.message-success')
						.addClass('show-message');
				}
			});
	};

	$module.submit(function(event){
		event.preventDefault();
		grecaptcha.execute();
	});
}
