'use strict';

var $module,
	isAnimationComplete,
	isStageDevelopmentSeen;

$module = $('.homepage-fullpage');

if ($module.length) {

var stageDev = require('./homepage-development-stages'),
	stageDevMobile = require('./homepage-development-stages/mobile');

	isAnimationComplete = false;
	isStageDevelopmentSeen = false;

	$module.fullpage(
		{
			navigation: true,
			navigationPosition: 'right',
			verticalCentered: false,

			afterRender: function(){

				d3.queue(1)
					.defer(function(cb){
						$('.homepage-loading-overlay').fadeOut();
						setTimeout(function(){ cb(null); }, 1000); })
					.defer(function(cb){
						$('.logo-init-position').removeClass('logo-init-position');
						$('.edge-init-position').removeClass('edge-init-position');
						setTimeout(function(){ cb(null); }, 2000);
					})
					.defer(function(cb){
						$('.homepage-logo-animation').fadeOut();
						$('.homepage-init').removeClass('homepage-init');
						setTimeout(function(){ cb(null); }, 500);
					})
					.defer(function(cb){
						$('.tagline-init').removeClass('tagline-init');
						setTimeout(function(){ cb(null); }, 1000);
					})
					.defer(function(cb){
						$('.btn-init').removeClass('btn-init');
						$('.more-info-init').removeClass('more-info-init');

						var $video = $('.fullpage-background-video');
						var $videoEl = $video[0];

						$videoEl.addEventListener('canplay', function () {
							$video.attr('data-autoplay', true)[0].play();
						})

						$videoEl.load();

						// Hide and get height to fix IE issue where Video doesn't show
						$video.hide();
						$video.height();
						$video.show();
						$video.height();

						isAnimationComplete = true;

						cb(null);
					});
			},
			onLeave: function(){
				if (!isAnimationComplete){
					$('.homepage-loading-overlay').fadeOut();
					$('.logo-init-position').removeClass('logo-init-position');
					$('.edge-init-position').removeClass('edge-init-position');
					$('.homepage-logo-animation').fadeOut();
					$('.homepage-init').removeClass('homepage-init');
					$('.tagline-init').removeClass('tagline-init');
					$('.btn-init').removeClass('btn-init');
					$('.more-info-init').removeClass('more-info-init');

					isAnimationComplete = true;
				}
			},
			afterLoad: function(anchorLink, index){
				if (index === 2 && !isStageDevelopmentSeen){

					$('.stages-intro').removeClass('initial-animate');
					$('.stages-topics-tabs').removeClass('initial-animate');

					isStageDevelopmentSeen = true;

					stageDev.initialize();
					stageDevMobile.initialize();
				}
			}
		});

	$module.find('.btn-next-section').click(function(){
		$.fn.fullpage.moveSectionDown();
	});
}

