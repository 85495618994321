import React, {PropTypes} from 'react';
import classnames from 'classnames';

const BtnFilterLabel = ({label, modifierClass, tabIndex, isStage, toggleStageInformation}, {setTabState, getTabState}) => {

	const handleClick = () => setTabState(tabIndex);

	return (<button
		className={classnames({
			'btn-filter-label': true,
			[modifierClass]: modifierClass,
			'is-selected': getTabState() === tabIndex,
			'is-stage-label': isStage
		})}
		onClick={handleClick}>
		{label}
	</button>);
};

BtnFilterLabel.propTypes = {
	label: PropTypes.string.isRequired,
	tabIndex: PropTypes.number.isRequired,
	modifierClass: PropTypes.string
};

BtnFilterLabel.contextTypes = {
	getTabState: PropTypes.func.isRequired,
	setTabState: PropTypes.func.isRequired
};

module.exports = BtnFilterLabel;
