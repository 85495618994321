import React from 'react';
import ResourceSearchApp from './ResourceSearchApp';

const $module = $('#resources-search');

if ($module.length){
	ReactDOM.render(
		<ResourceSearchApp initialState={JSON.parse(decodeURI(document.getElementById('initial-state').innerHTML))}/>,
		document.getElementById('resources-search')
	);
}
